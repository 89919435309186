nav.small {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  display: none;
  position: fixed;
  overflow: hidden;
  background: $yellow;

  a {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      display: block;
      margin-top: 5px;
      right: 0;
      background: $black;
      transition: 0.4s ease;
    }

    &:hover {
      color: pink;

      &:after {
        width: 100%;
        left: 0;
        background: $black;
      }
    }
  }

  .nav-columns {
    transform: translateY(125px);
    display: flex;
    padding: 0 32px;

    @include media('<=1024px') {
      flex-direction: column;

      @include media('<=phone') {
        padding: 0 16px;
      }
    }

    .nav-column {
      width: 45%;

      @include media('<=1024px') {
        width: 100%;
      }

      .nav-links {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-bottom: 2.4rem;

          @include media('<=smDesktop') {
            margin-bottom: 1.8rem;
          }

          @include media('<=tablet') {
            margin-bottom: 1.7rem;
          }

          @include media('<=phone') {
            margin-bottom: 1.6rem;
          }

          a,
          span {
            font-weight: 600;
            font-size: 2.8rem;
            text-decoration: none;
            color: $black;

            @include media('<=smDesktop') {
              font-size: 2rem;
            }

            @include media('<=tablet') {
              font-size: 1.8rem;
            }

            @include media('<=phone') {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
