$strength-colors: (red, orangered, orange, yellowgreen, #00c301);

$strength-gap: 5px;

.badge-success {
  background-color: #00c301;
}

.badge-warning {
  background-color: orange;
}

.badge-danger {
  color: white;
  background-color: red;
}

.strength-meter {
  position: relative;
  height: 5px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
  overflow: hidden;
  z-index: 0;
  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 2;
  }
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  transition: width 0.5s ease-in-out, background 0.25s;
  @for $i from 1 through 5 {
    &[data-strength='#{$i - 1}'] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}
