.general-dropdown {
  .dropdown-menu {
    padding: 0.7rem 0rem;
    font-size: 0.875rem;
    line-height: 22px;
    background: white;
    color: #2e293c;
    border: none;
    box-shadow: 0 0 20px 5px rgba(#000000, 0.1);
    z-index: 1;

    .dropdown-item {
      width: 100%;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0%;
        height: 1px;
        bottom: 0%;
        margin-top: -0.5px;
        background: #000000;
      }

      &:before {
        left: -2.5px;
      }

      &:after {
        right: 2.5px;
        background: #000000;
        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
      }

      &:hover:before {
        background: #000000;
        width: 100%;
        transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      }

      &:hover:after {
        background: transparent;
        width: 100%;
        transition: 0s;
      }

      &:not(:last-child) {
        margin-bottom: 13px;
      }
    }
  }

  .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }

  &.open {
    .dropdown-menu {
      -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
      visibility: visible;
    }
  }
}
