@import './helpers/varibles';
@import './helpers/breakpoints';
@import '../../components/styles.scss';

html,
body {
  visibility: visible;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

p {
  word-break: break-all;
}

input,
textarea {
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

textarea {
  line-height: 20px;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;

  @include media('<=1560px') {
    width: 1280px;
    min-width: 1280px;
  }

  @include media('<=desktop') {
    width: 1080px;
    min-width: 1080px;
  }

  @include media('<=1080px') {
    width: 100%;
    min-width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;

    @include media('<=phone') {
      padding: 0 16px;
    }
  }

  .v-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
  }
}

.page {
  height: 100vh;
  width: 100%;
  background: $white;

  .row {
    display: flex;
    align-items: center;
  }
}

/* General */
button {
  box-shadow: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
}

/* Filepond */
.filepond--wrapper {
  margin-bottom: 20px;

  .filepond--root {
    margin-bottom: 0px;

    .filepond--credits {
      display: none;
    }

    .filepond--drop-label {
      label {
        font-weight: 500;
        color: $black !important;

        &.filepond--label-action {
          text-decoration: underline;
          text-decoration-style: dashed;
        }
      }
    }

    .filepond--drip {
      opacity: 1 !important;
      background-color: rgba(229, 231, 235, 1) !important;
    }
  }
}

// Helpers
.layout-rodal {
  display: flex;
  align-items: center;

  .rodal-dialog {
    padding: 0px !important;
  }
}

.ct-toast {
  min-height: unset !important;
  border-left: unset !important;

  .ct-toast-success {
    svg {
      path {
        fill: green !important;
      }
    }
  }

  .ct-toast-error {
    svg {
      path {
        fill: red !important;
      }
    }
  }
}

.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}

.masonry-grid-column {
  padding-left: 30px;
  background-clip: padding-box;
}

.masonry-grid-column > div {
  margin-bottom: 30px;
}

.c-scrollbar {
  display: none;
}

.quill {
  background-color: #edf2f7;
  border-radius: 0.25rem;

  .ql-toolbar {
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }

  .ql-container {
    border: 0px !important;
  }
}

/* Pages */
.swiper-button-next,
.swiper-button-prev {
  display: none;
}

.search-box {
  background: #edf2f7;
  height: 25px;
  border-radius: 50px;
  padding: 10px;

  .search-input {
    border: none;
    background: none;
    outline: none;
    float: left;
    color: black;
    font-size: 15px;
    transition: 0.4s;
    line-height: 25px;
    width: 180px;
    padding: 0 7px;
  }

  .search-btn {
    color: black;
    float: right;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 2s;
  }
}

.order-details {
  .product-details {
    border: 1px solid #e0e0e0;
    cursor: pointer;
    padding: 10px;
  }

  .product-item {
    .thumbnail {
      min-width: 125px;
      min-height: 125px;
      max-width: 125px;
      max-height: 125px;
      padding-right: 15px;

      @include media('<=smDesktop') {
      }

      @include media('<=tablet') {
      }

      @include media('<=phone') {
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
      }
    }
  }

  .tracker {
    .order-tracking {
      text-align: center;
      width: 25%;
      position: relative;
      display: block;

      &:before {
        content: '';
        display: block;
        height: 3px;
        width: calc(100% - 40px);
        background-color: #e0e0e0;
        top: 13px;
        position: absolute;
        left: calc(-50% + 20px);
        z-index: 0;
      }

      &:first-child:before {
        display: none;
      }

      .is-complete {
        display: block;
        position: relative;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        border: 0px solid #afafaf;
        background-color: #e0e0e0;
        margin: 0 auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
        z-index: 2;

        &:after {
          display: block;
          position: absolute;
          content: '';
          height: 14px;
          width: 7px;
          top: -2px;
          bottom: 0;
          left: 5px;
          margin: auto 0;
          border: 0px solid #afafaf;
          border-width: 0px 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
        }
      }

      p {
        color: $tertiary;
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 0;
        line-height: 20px;

        @include media('<=tablet') {
          font-size: 14px;
        }

        @include media('<=phone') {
          font-size: 12px;
        }

        span {
          font-size: 12px;

          @include media('<=tablet') {
            font-size: 11px;
          }

          @include media('<=phone') {
            font-size: 9px;
          }
        }
      }

      &.completed {
        p {
          color: $text;
        }

        .is-complete {
          border-color: #27aa80;
          border-width: 0px;
          background-color: #27aa80;

          &:after {
            border-color: $white;
            border-width: 0px 3px 3px 0;
            width: 7px;
            left: 11px;
            opacity: 1;
          }
        }

        &:before {
          background-color: #27aa80;
        }
      }
    }
  }
}
