$small-loader-width: 16px;
$midway-loader-width: 28px;
$medium-loader-width: 30px;
$large-loader-width: 40px;

.material-loader {
  position: relative;
  margin: 0 auto;
  width: $small-loader-width;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.midway {
    width: $midway-loader-width;
  }

  &.medium {
    width: $medium-loader-width;
  }

  &.large {
    width: $large-loader-width;
  }
}

.material-loader > .circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.material-loader > .circular > .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 5px;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
