.header {
  position: fixed;
  width: 100%;
  height: 6rem;
  max-height: 6rem;
  z-index: 3;
  transition: background-color 0.3s linear, box-shadow 0.5s ease;

  .logo {
    a {
      font-size: 1.2rem;
      letter-spacing: 0.5rem;
      text-decoration: none;
      font-weight: 700;
      color: $black;
    }
  }

  &--scrolled {
    background-color: $white;
    box-shadow: 0px 6px 25px -7px rgba(0, 0, 0, 0.32);
  }

  &--menu-opened {
    background-color: transparent;
    box-shadow: unset;
  }

  .nav-toggle {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media('<=tablet') {
      margin-right: 24px;
    }

    @include media('<=phone') {
      width: 20px;
      margin-right: 15px;
    }

    .hamburger-menu {
      width: 25px;

      span {
        text-align: center;
        margin-bottom: 0.3rem;
        background: $black;
        height: 2px;
        width: 100%;
        display: block;
      }
    }

    .hamburger-menu-close {
      position: absolute;
      display: none;

      svg {
        @include media('<=tablet') {
          width: 56px;
        }
        @include media('<=phone') {
          width: 48px;
        }
      }
    }
  }
}

nav.large {
  ul {
    display: table;
    list-style: none;
    padding: 0;
    text-align: center;

    li {
      display: table-cell;
      position: relative;
      padding: 10px 0;

      a.menu-item,
      button.menu-item {
        color: $black;
        font-size: 0.85rem;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        padding: 15px 0px;
        margin: 0 10px;
        position: relative;

        a.menu-item {
          font-weight: 600;
        }

        button.menu-item {
          font-weight: 400;
        }

        &:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: '';
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background: $black;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }

        &:hover:after {
          width: 100%;
          left: 0;
        }

        &.active:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: '';
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          background: $black;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 100%;
        }
      }

      span.menu-item {
        cursor: pointer;
      }

      .sub-menu {
        position: absolute;
        z-index: 500;
        background-color: $white;
        box-shadow: 0 0 20px 5px rgba(#000000, 0.1);
        transition: all 0.5s ease;
        margin-top: 35px;
        padding: 15px;
        opacity: 0;
        visibility: hidden;

        &.open {
          margin-top: 15px;
          visibility: visible;
          opacity: 1;
        }

        &.mega-menu {
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .list-item {
            padding-top: 7px;
            padding-bottom: 7px;
            width: 100%;
            position: relative;

            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 0%;
              height: 1px;
              top: 85%;
              margin-top: -0.5px;
              background: #000000;
            }

            &:before {
              left: -2.5px;
            }

            &:after {
              right: 2.5px;
              background: #000000;
              transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
            }

            &:hover:before {
              background: #000000;
              width: 100%;
              transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
            }

            &:hover:after {
              background: transparent;
              width: 100%;
              transition: 0s;
            }

            a {
              color: #000000;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
