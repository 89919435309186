$switchWidth: 38px;
$switchHeight: 23px;

.checkbox {
  cursor: pointer;
  position: relative;
}

.checkbox > input,
.checkbox > div {
  display: inline-block;
  vertical-align: top;
  color: white;
}

.checkbox > div {
  width: $switchWidth;
  height: $switchHeight;
  border-radius: $switchHeight;
  transition: background-color 0.3s;
  position: relative;
  overflow: auto;
  pointer-events: none;
}

input.switch:checked ~ div {
  transition: background-color 0.3s;
}

input.switch:not(:checked) ~ div {
  transition: background-color 0.3s 0.3s;
}

.checkbox > div::before,
.checkbox > div::after {
  content: '';
  position: absolute;
  top: 0;
  width: $switchHeight;
  height: $switchHeight;
  background-color: white;
  border-radius: 50%;
}

.checkbox > div::before {
  left: 0;
}

.checkbox > div::after {
  right: 0;
}

input.switch:checked ~ div::before {
  transform: scale(0.8) translateY(50px);
}

input.switch:checked ~ div::after {
  transform: scale(0.8) translateY(0);
}

input.switch:not(:checked) ~ div::before {
  transform: scale(0.8) translateY(0px);
}

input.switch:not(:checked) ~ div::after {
  transform: scale(0.8) translateY(-50px);
}

input.switch:not(:checked) ~ div {
  background-color: lightgray;
}

input.switch:checked ~ div {
  background-color: black;
}

input.switch:not(:checked) ~ div {
  background-color: lightgray;
}

.checkbox > input {
  opacity: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  margin: 0;
  height: 100%;
  z-index: 19;
}

input.switch:checked ~ div::before,
input.switch:checked ~ div::after {
  transition: transform 0.3s 0s;
}

input.switch:not(:checked) ~ div::before,
input.switch:not(:checked) ~ div::after {
  transition: transform 0.3s 0.3s;
}
