.menu-dropdown {
  min-width: 100px;
  right: 0;
  box-shadow: 0 0 20px 5px rgba(#000000, 0.1);
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;

  &.small {
    top: 40px;
  }

  &.large {
    top: 50px;
  }

  &.open {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .menu-content {
    overflow: hidden;

    > ul {
      display: block !important;

      > li {
        font-size: 15px;
        padding-top: 0px;
        padding-bottom: 5px;
        display: block !important;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 0%;
          height: 1px;
          bottom: 0%;
          margin-top: -0.5px;
          background: #000000;
        }

        &:before {
          left: -2.5px;
        }

        &:after {
          right: 2.5px;
          background: #000000;
          transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &:hover:before {
          background: #000000;
          width: 100%;
          transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &:hover:after {
          background: transparent;
          width: 100%;
          transition: 0s;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
